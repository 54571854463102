import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useDeleteShift,
  useFetchHolidays,
  useFetchOneShiftSchedule,
  useFetchShiftSchedules,
  useUpdateDayWorking,
  useUpdateDayOffShift,
} from "@bms/hooks";
import moment from "moment";
import { DATE_TIME_FORMAT } from "@bms/constants";
import { ShiftSchedules } from "@bms/types";
import { handleDateOff } from "./SchedulerBox/Components";
import dayjs from "dayjs";

const useCalendar = () => {
  const [openScheduleDialog, setOpenScheduleDialog] = useState<boolean>(false);
  const [openRegularDialog, setOpenRegularDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentDay, setCurrentDay] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [scheduleList, setScheduleList] = useState<{
    data: ShiftSchedules[];
  }>({
    data: [],
  });
  const [shiftDetail, setShiftDetail] = useState<{
    workerId: number | null;
    leaveRequestId: number | null;
  }>({
    workerId: null,
    leaveRequestId: null,
  });

  const { data: holidaysData } = useFetchHolidays();
  const { data, isLoading, isSuccess } = useFetchShiftSchedules(currentDate);
  const { mutate: mutateDelete, isLoading: isSubmitting } = useDeleteShift();
  const { mutate: mutateUpdate, isLoading: isSubmittingUpdate } =
    useUpdateDayOffShift();
  const { mutate: mutateDayWorkings, isLoading: isSubmittingWorking } =
    useUpdateDayWorking();

  const startDay = useMemo(() => {
    return dayjs(new Date(currentDay?.startDate)).format(
      DATE_TIME_FORMAT.PAYLOAD
    );
  }, [currentDay?.startDate]);
  const endDay = useMemo(() => {
    return dayjs(new Date(currentDay?.endDate)).format(
      DATE_TIME_FORMAT.PAYLOAD
    );
  }, [currentDay?.endDate]);

  const {
    data: scheduleDetailData,
    isLoading: isDetailLoading,
    isSuccess: isDetailSuccess,
  } = useFetchOneShiftSchedule(startDay, endDay);

  // const onUpdateWorkerShift = async () => {
  const onDeleteWorkerShift = async () => {
    // mutateUpdate(
    mutateDelete(
      {
        // selectedDate: dayjs(new Date(currentDay?.endDate)).format(
        //   DATE_TIME_FORMAT.PAYLOAD
        // ),
        workerId: shiftDetail.workerId,
        leaveRequestId: shiftDetail.leaveRequestId,
      },
      {
        onSuccess() {
          setOpenDeleteDialog(false);
          setOpenScheduleDialog(false);
        },
      }
    );
  };

  const onDeleteDayWorkings = async () => {
    mutateDayWorkings({
      worker_ids: scheduleDetailData?.leave_requests?.map(
        (item: any) => item.worker.id
      ),
      date_on: moment(currentDay.startDate).format("YYYY/MM/DD"),
    });
    setOpenRegularDialog(false);
    setOpenScheduleDialog(false);
  };

  const handleStatusShift = useCallback(() => {
    const current = moment().format("YYYY-MM-DD");
    const dayBefore = moment(new Date(currentDay?.startDate)).format(
      "YYYY-MM-DD"
    );
    const isBeforeDay = moment(current).isBefore(dayBefore);
    const isCurrentDay = moment(current).isSame(dayBefore);

    return isCurrentDay || isBeforeDay;
  }, [currentDay?.startDate]);

  useEffect(() => {
    setScheduleList({
      data: [],
    });
    if (isSuccess) {
      const parseData: any = (data.leave_requests as ShiftSchedules[])
        .map((item) => {
          return {
            ...item,
            holidays: handleDateOff(
              holidaysData,
              new Date(`${item.date_on} 00:00:01`)
            ),
            startDate: new Date(`${item.date_on} 00:00:01`),
            endDate: new Date(`${item.date_on} 23:59:59`),
            allDay: true,
          };
        })
        .filter(
          (item) => item.worker && !item.holidays && item.status === "on"
        );
      setScheduleList({
        data: parseData.sort(
          (d1: any, d2: any) =>
            new Date(d1.startDate).getTime() - new Date(d2.startDate).getTime()
        ),
      });
    }
  }, [data, holidaysData, isSuccess]);

  return {
    openScheduleDialog,
    openRegularDialog,
    openDeleteDialog,
    currentDate,
    isLoading,
    scheduleList,
    scheduleDetailData,
    isDetailLoading,
    isDetailSuccess,
    isSubmitting,
    holidaysData,
    currentDay,
    isSubmittingWorking,
    data,
    handleStatusShift,
    onDeleteDayWorkings,
    setShiftDetail,
    // onUpdateWorkerShift,
    onDeleteWorkerShift,
    setCurrentDate,
    setCurrentDay,
    setOpenDeleteDialog,
    setOpenScheduleDialog,
    setOpenRegularDialog,
  };
};

export type Props = ReturnType<typeof useCalendar>;

export default useCalendar;
