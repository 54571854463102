export const getAccessToken = () => localStorage.getItem("access_token") || "";
export const getUser = () => localStorage.getItem("user") || "";

export const formatTel = (value: string) => {
  if (!value) return "";
  return value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

export const formatDay = (value: any) => {
  if (!value) return "";
  // ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  return ["日", "月", "火", "水", "木", "金", "土"][new Date(value).getDay()];
};

export const handleUpdatePhoto = (element: any, elementOriginal: any) => {
  if (
    elementOriginal?.after_photo &&
    elementOriginal?.after_photo?.after_photo_key !==
      element?.after_photo_key &&
    elementOriginal?.after_photo?.after_photo_key !==
      element?.after_photo?.after_photo_key
  ) {
    element.delete_after_photo = true;
  }
  if (
    elementOriginal?.before_photo &&
    elementOriginal?.before_photo?.before_photo_key !==
      element?.before_photo_key &&
    elementOriginal?.before_photo?.before_photo_key !==
      element?.before_photo?.before_photo_key
  ) {
    element.delete_before_photo = true;
  }
  if (element.after_photo?.image_path || !element.after_photo) {
    delete element.after_photo;
  }
  if (element.before_photo?.image_path || !element.before_photo) {
    delete element.before_photo;
  }
};
