import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { notification } from "antd";
import { get } from "lodash";
import { RangePickerProps } from "antd/es/date-picker";

import {
  useFetchOneServiceCard,
  useSubmitRequestContent,
  useSubmitReturnDateHistory,
} from "@bms/hooks";
import { openNotification } from "@bms/components";

export type ReceivedProps = Record<string, any>;

const useWorkingHours = (props: ReceivedProps) => {
  const { id } = useParams();

  const [openStartDialog, setOpenStartDialog] = useState<boolean>(false);
  const [openEndDialog, setOpenEndDialog] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isStart, setIsStart] = useState<boolean>(false);
  const [isDatePickerStart, setIsDatePickerStart] = useState<boolean>(false);
  const [isDatePickerEnd, setIsDatePickerEnd] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const [flagOnClick, setFlagOnClick] = useState<boolean>(false);

  const { mutate, isLoading: isLoadingButton } = useSubmitRequestContent();
  const { data, isSuccess, isLoading, isFetching } = useFetchOneServiceCard(id);
  const { mutate: returnDateHistoryAction } = useSubmitReturnDateHistory();
  const [isUpdate, setIsUpdate] = useState<boolean>(true);

  const returnOrderDate = useMemo(() => {
    const currentDay = dayjs().format("YYYY-MM-DD");

    if (isSuccess) {
      const orderReturnDateLength =
        data?.request_content?.return_date_histories?.length;
      if (orderReturnDateLength > 0) {
        const getReturnOrderDate = get(
          data,
          `request_content.return_date_histories.[${orderReturnDateLength - 1}]`
        );
        const isConditionalReturnOrderDate =
          getReturnOrderDate?.return_order_date
            ? dayjs(
                dayjs(getReturnOrderDate?.return_order_date).format(
                  "YYYY-MM-DD"
                )
              ).isSame(dayjs(currentDay))
            : null;
        return isConditionalReturnOrderDate ? getReturnOrderDate : {};
      } else {
        const isConditionalReturnOrderDate = dayjs(
          dayjs(data?.request_content?.return_order_date).format("YYYY-MM-DD")
        ).isSame(dayjs(currentDay));
        return isConditionalReturnOrderDate
          ? { return_order_date: data?.request_content?.return_order_date }
          : {};
      }
    }
    return {};
  }, [data, isSuccess]);

  const onSubmitStart = async (startTime?: Dayjs | null) => {
    const { request_content } = data || {};
    const orderReturnDateLength =
      request_content?.return_date_histories?.length;
    const getReturnOrderDate = get(
      request_content,
      `return_date_histories.[${orderReturnDateLength - 1}]`
    );
    const payload = {
      // ...(returnOrderDate?.return_order_date
      //   ? {
      //       actual_start_time: startTime
      //         ? dayjs(startTime).toISOString()
      //         : request_content?.actual_start_time
      //         ? dayjs(request_content?.actual_start_time).toISOString()
      //         : dayjs().toISOString(),
      //     }
      //   : {
      //       actual_start_time: request_content?.actual_start_time
      //         ? dayjs(request_content?.actual_start_time).toISOString()
      //         : dayjs().toISOString(),
      //     }),
      actual_start_time: startTime,
      service_card_id: data?.id,
      ...(returnOrderDate?.return_order_date
        ? {
            status:
              getReturnOrderDate?.actual_start_time &&
              getReturnOrderDate?.actual_end_time
                ? "finished"
                : "in_progress",
          }
        : {
            status:
              data?.request_content?.actual_start_time &&
              data?.request_content?.actual_end_time
                ? "finished"
                : "in_progress",
          }),

      has_notification: true,
    };

    const isConditional = dayjs().isAfter(
      dayjs(data?.request_content?.return_order_date).format("YYYY-MM-DD")
    );

    const isValidateTime = dayjs(
      data?.request_content?.actual_end_time
    ).isBefore(dayjs(startTime));

    if (isValidateTime && !isConditional) {
      notification["error"]({
        message: "The end time must be greater than the start time",
      });
      return;
    }

    if (returnOrderDate?.return_order_date) {
      returnDateHistoryAction(
        {
          payload: {
            is_mobile: true,
            actual_start_time: startTime
              ? dayjs(startTime).toISOString()
              : dayjs().toISOString(),
            request_content_id: data?.request_content?.id,
            return_order_date: returnOrderDate?.return_order_date,
          },
          returnDateHistoryId: returnOrderDate?.id,
        },
        {
          onError(error: any) {
            setIsUpdate(false);
            notification["error"]({
              message: { error }?.error?.response?.data?.error || "Failed",
            });
          },
        }
      );
    }

    mutate(
      {
        payload,
        requestContentId: data?.request_content?.id,
      },
      {
        onSuccess({ data }) {
          if (isUpdate) {
            openNotification();
          }
          setIsStart(true);
          setOpenStartDialog(false);
          setStartTime(data.actual_actual_end_time);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  const onSubmitEnd = async (endTime?: Dayjs | null) => {
    // const { request_content } = data || {};
    const payload = {
      // ...(returnOrderDate?.return_order_date
      //   ? {
      //       actual_end_time: endTime,
      //       ? dayjs(endTime).toISOString()
      //       : request_content?.actual_end_time
      //       ? dayjs(request_content?.actual_end_time).toISOString()
      //       : dayjs().toISOString(),
      //     }
      //   : {
      //       actual_end_time: request_content?.actual_end_time,
      //       ? dayjs(request_content?.actual_end_time).toISOString()
      //       : dayjs().toISOString(),
      //     }),
      actual_end_time: endTime,
      service_card_id: data?.id,
      status: "finished",
      has_notification: true,
    };

    const isValidateTime = dayjs(
      data?.request_content?.actual_start_time
    ).isAfter(dayjs(endTime));

    const isConditional = dayjs().isAfter(
      dayjs(data?.request_content?.return_order_date).format("YYYY-MM-DD")
    );

    if (isValidateTime && !isConditional) {
      notification["error"]({
        message: "The end time must be greater than the start time",
      });
      return;
    }

    if (returnOrderDate?.return_order_date) {
      returnDateHistoryAction(
        {
          payload: {
            is_mobile: true,
            actual_end_time: endTime
              ? dayjs(endTime).toISOString()
              : dayjs().toISOString(),
            request_content_id: data?.request_content?.id,
            return_order_date: returnOrderDate?.return_order_date,
          },
          returnDateHistoryId: returnOrderDate?.id,
        },
        {
          onError(error: any) {
            setIsUpdate(false);
            notification["error"]({
              message: { error }?.error?.response?.data?.error || "Failed",
            });
          },
        }
      );
    }

    mutate(
      {
        payload,
        requestContentId: data?.request_content?.id,
      },
      {
        onSuccess({ data }) {
          if (isUpdate) {
            openNotification();
          }

          setIsStart(true);
          setIsEdit(true);
          setOpenEndDialog(false);
          setStartTime(data.actual_end_time);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  const onClickEnd = () => {
    setOpenEndDialog(true);
  };

  const diffTime = useCallback(() => {
    if (startTime && endTime) {
      const diff = Math.abs(
        new Date(endTime).getTime() - new Date(startTime).getTime()
      );
      const diffAsDate = new Date(diff);

      return {
        minutes: diffAsDate.getMinutes(),
        hours: Math.floor(diff / 1000 / 60 / 60),
      };
    }

    return {
      minutes: 0,
      hours: 0,
    };
  }, [endTime, startTime]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    if (isSuccess) {
      const { request_content } = data || {};
      const orderReturnDateLength =
        request_content?.return_date_histories?.length;
      const currentDay = dayjs().format("YYYY-MM-DD");

      const getReturnOrderDate = get(
        request_content,
        `return_date_histories.[${orderReturnDateLength - 1}]`
      );
      const isConditionalReturnOrderDate = getReturnOrderDate?.return_order_date
        ? dayjs(
            dayjs(getReturnOrderDate?.return_order_date).format("YYYY-MM-DD")
          ).isSame(dayjs(currentDay))
        : null;
      const isConditionalStartTime = isConditionalReturnOrderDate
        ? dayjs(currentDay).isSame(
            dayjs(getReturnOrderDate?.actual_start_time).format("YYYY-MM-DD")
          )
        : dayjs(currentDay).isSame(
            dayjs(request_content?.actual_start_time).format("YYYY-MM-DD")
          );
      const isConditionalEndTime = isConditionalReturnOrderDate
        ? dayjs(currentDay).isSame(
            dayjs(getReturnOrderDate?.actual_end_time).format("YYYY-MM-DD")
          )
        : dayjs(currentDay).isSame(
            dayjs(request_content?.actual_end_time).format("YYYY-MM-DD")
          );

      if (isConditionalReturnOrderDate) {
        setStartTime(getReturnOrderDate?.actual_start_time);
        setEndTime(getReturnOrderDate?.actual_end_time);
      } else {
        setStartTime(request_content?.actual_start_time);
        setEndTime(request_content?.actual_end_time);
      }

      if (
        isConditionalReturnOrderDate &&
        !isConditionalStartTime &&
        !isConditionalEndTime
      ) {
        setIsStart(false);
        setIsEdit(false);
        return;
      }

      if (
        isConditionalReturnOrderDate &&
        isConditionalStartTime &&
        !isConditionalEndTime
      ) {
        setIsStart(true);
        return;
      }

      if (
        isConditionalReturnOrderDate &&
        isConditionalStartTime &&
        isConditionalEndTime
      ) {
        setIsEdit(true);
        setIsStart(true);
        return;
      }

      if (
        request_content?.actual_start_time &&
        request_content?.actual_end_time
      ) {
        setIsEdit(true);
        setIsStart(true);
      }

      if (
        request_content?.actual_start_time &&
        !request_content?.actual_end_time
      ) {
        setIsStart(true);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  useEffect(() => {
    const querySelector = `.datepicker-start .ant-picker-input input`;
    const [dateInput] = document.querySelectorAll(querySelector);
    if (dateInput) {
      handleFormatValue(dateInput, true);
    }

    const queryEndSelector = `.datepicker-end .ant-picker-input input`;
    const [dateEndInput] = document.querySelectorAll(queryEndSelector);
    if (dateEndInput) {
      handleFormatValue(dateEndInput, false);
    }
  });

  const handleFormatValue = (dateInput: any, flagTime: any) => {
    dateInput.addEventListener("keyup", (e: any) => {
      let sanitizedValue = e.target.value.replace(/[^\d]/g, "");
      if (sanitizedValue.length >= 12) {
        sanitizedValue = sanitizedValue.replace(
          /^(\d{4})(\d{2})(\d{2})(\d{2})?(\d{2})?/,
          "$1-$2-$3 $4:$5"
        );

        if (flagTime) {
          setStartTime(dayjs(sanitizedValue).format("YYYY-MM-DD hh:mm"));
        } else {
          setEndTime(dayjs(sanitizedValue).format("YYYY-MM-DD hh:mm"));
        }
      }
    });
  };

  return {
    ...props,
    openStartDialog,
    isStart,
    openEndDialog,
    isEdit,
    isLoadingButton,
    startTime,
    endTime,
    isDatePickerEnd,
    isDatePickerStart,
    isLoading,
    isFetching,
    disabledDate,
    setIsDatePickerStart,
    setIsDatePickerEnd,
    diffTime,
    onClickEnd,
    setOpenEndDialog,
    onSubmitEnd,
    onSubmitStart,
    setOpenStartDialog,
    flagOnClick,
    setFlagOnClick,
    setStartTime,
    setEndTime,
  };
};

export type Props = ReturnType<typeof useWorkingHours>;

export default useWorkingHours;
