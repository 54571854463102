/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";

import { useFetchOneServiceCard, useSubmitWorkReport } from "@bms/hooks";
import { ServiceCard } from "@bms/types";
import { validation } from "./schema";
import { openNotification } from "@bms/components";
import { omit } from "lodash";
import { QUERY_NAME } from "@bms/constants";
import { useQueryClient } from "@tanstack/react-query";
import { handleUpdatePhoto } from "@bms/utility";
import axios from "@bms/libs/axios";

export type ReceivedProps = Record<string, any>;

const useWorkReport = (props: ReceivedProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [isView, setIsView] = useState<boolean>(true);
  const [workReportId, setWorkReportId] = useState<number | null>(null);
  const [openModalDeleteImg, setOpenModalDeleteImg] = useState<string | null>(
    null
  );
  const [loadingImage, setLoadingImage] = useState<string | null>(null);

  const { data, isLoading, isSuccess, isFetching } = useFetchOneServiceCard(id);
  const { mutate, isLoading: isLoadingButton } = useSubmitWorkReport();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation()),
  });

  const onOpenModalDeleteUpload = (name: string) => setOpenModalDeleteImg(name);
  const onCloseModalDeleteUpload = () => setOpenModalDeleteImg(null);

  const onSubmit = async (values: any) => {
    const formData = new FormData();
    const payload: any = [values];
    for (let index = 0; index < payload?.length; index++) {
      handleUpdatePhoto(payload[index], data?.work_reports?.[index]);
    }
    payload
      .map((obj: any) => ({
        service_card_id: id,
        has_notification: true,
        ...obj,
      }))
      .forEach((item: any) => {
        Object.keys(item).forEach((key) => {
          formData.append(
            `work_report_attributes[]${key}`,
            key === "before_photo"
              ? item["before_photo_key"]
              : key === "after_photo"
              ? item["after_photo_key"]
              : item[key]
          );
        });
      });

    formData.delete("work_report_attributes[]before_photo_key");
    formData.delete("work_report_attributes[]after_photo_key");

    mutate(
      {
        payload: formData,
        workReportId,
      },
      {
        onSuccess: () => {
          openNotification();
          setIsView(true);
          setWorkReportId(null);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  const uploadImage = useCallback(
    async (name: string, filePhoto: any) => {
      setLoadingImage(name);
      try {
        const formData = new FormData();
        formData.append("file_photo", filePhoto);

        const { data } = await axios({
          method: "POST",
          url: "/s3",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setValue(name, data.url);
        setValue(`${name}_key`, data.key);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoadingImage(null);
      }
    },
    [setValue]
  );

  const deleteImage = useCallback(async (name: string, key: string) => {
    setLoadingImage(name);
    try {
      await axios({
        method: "DELETE",
        url: "/s3/delete",
        data: {
          key,
        },
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingImage(null);
    }
  }, []);

  useEffect(() => {
    if (workReportId) {
      const result = (data as ServiceCard)?.work_reports?.find(
        (item) => item.id === workReportId
      );
      reset(omit(result, ["has_notification"]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workReportId]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    serviceCardData: data as ServiceCard,
    isLoading,
    isFetching,
    isView,
    isLoadingButton,
    errors,
    openModalDeleteImg,
    onOpenModalDeleteUpload,
    onCloseModalDeleteUpload,
    reset,
    setWorkReportId,
    watch,
    setValue,
    handleSubmit,
    onSubmit,
    setIsView,
    register,
    uploadImage,
    deleteImage,
    loadingImage,
  };
};

export type Props = ReturnType<typeof useWorkReport>;

export default useWorkReport;
