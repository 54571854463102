import { FC } from "react";
import { TextField, InputLabel } from "@mui/material";

import { UploadCaptureIcon } from "@bms/assets";
import useWorkReport, { Props, ReceivedProps } from "./hook";
import { Styles } from "./styled";
import { get } from "react-hook-form";
import { ModalDelete, ModalUpload } from "@bms/components";

const WorkReportLayout: FC<Props> = ({
  register,
  setValue,
  watch,
  onOpenModalUpload,
  onCloseModalUpload,
  onOpenModalDeleteUpload,
  onCloseModalDeleteUpload,
  openModalDeleteImg,
  openModalUpload,
  fields,
  errors,
  isView,
  uploadImage,
  deleteImage,
  loadingImage,
}) => {
  const handleUrl = (value: File | any) => {
    if (value?.image_path) {
      return value.image_path;
    }

    if (value?.name) {
      return URL.createObjectURL(value);
    }

    return value || UploadCaptureIcon;
  };

  return (
    <Styles className="work-report">
      <div className="main">
        <div className="field-wrapper">
          {fields.map((_, index) => {
            const beforePhoto = watch(
              `work_report_attributes.${index}.before_photo`
            );
            const beforePhotoName = `work_report_attributes.${index}.before_photo`;
            const beforePhotoKey = `work_report_attributes.${index}.before_photo_key`;
            const afterPhoto = watch(
              `work_report_attributes.${index}.after_photo`
            );
            const afterPhotoName = `work_report_attributes.${index}.after_photo`;
            const afterPhotoKey = `work_report_attributes.${index}.after_photo_key`;

            const errorTitle = get(
              errors,
              `work_report_attributes.${index}.title.message`,
              null
            );
            const errorComment = get(
              errors,
              `work_report_attributes.${index}.comment.message`,
              null
            );

            return (
              <div className="box-item" key={index}>
                <div className="input-field">
                  <InputLabel>
                    作業タイトル <span>(報告書に記載)</span>
                  </InputLabel>
                  <TextField
                    {...register(`work_report_attributes.${index}.title`)}
                    name={`work_report_attributes.${index}.title`}
                    placeholder="水道のパッキンが割れてました。"
                    disabled={isView}
                  />
                  {errorTitle && <p className="error">{errorTitle}</p>}
                </div>

                <div className="upload-wrapper">
                  <div className="item">
                    <InputLabel>
                      作業前写真 <span>(報告書に記載)</span>
                    </InputLabel>
                    <div>
                      {loadingImage === beforePhotoName ? (
                        <p>アップロード中...</p>
                      ) : (
                        <img
                          onClick={() => {
                            if (isView) return;
                            beforePhoto
                              ? onOpenModalDeleteUpload(beforePhotoName)
                              : onOpenModalUpload(beforePhotoName);
                          }}
                          src={handleUrl(beforePhoto)}
                          alt=""
                          className={`${beforePhoto ? "photo" : ""}`}
                        />
                      )}
                      {(openModalUpload === beforePhotoName ||
                        openModalDeleteImg === beforePhotoName) && (
                        <>
                          <ModalUpload
                            beforeUpload={(e) => {
                              onCloseModalUpload();
                              uploadImage(beforePhotoName, e);
                            }}
                            name={beforePhotoName}
                            url={beforePhoto}
                            isOpen={Boolean(openModalUpload)}
                            setValue={setValue}
                            onClose={onCloseModalUpload}
                          />
                          <ModalDelete
                            isOpen={Boolean(openModalDeleteImg)}
                            isSubmitting={false}
                            onDeleteRecord={async () => {
                              setValue(beforePhotoName, null);
                              setValue(beforePhotoKey, null);
                              deleteImage(
                                beforePhotoName,
                                watch(beforePhotoKey)
                              );
                              onCloseModalDeleteUpload();
                            }}
                            onClose={onCloseModalDeleteUpload}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="item">
                    <InputLabel>
                      作業後写真 <span>(報告書に記載)</span>
                    </InputLabel>
                    <div>
                      {loadingImage === afterPhotoName ? (
                        <p>アップロード中...</p>
                      ) : (
                        <img
                          onClick={() => {
                            if (isView) return;
                            afterPhoto
                              ? onOpenModalDeleteUpload(afterPhotoName)
                              : onOpenModalUpload(afterPhotoName);
                          }}
                          src={handleUrl(afterPhoto)}
                          alt=""
                          className={`${afterPhoto ? "photo" : ""}`}
                        />
                      )}

                      {(openModalUpload === afterPhotoName ||
                        openModalDeleteImg === afterPhotoName) && (
                        <>
                          <ModalUpload
                            beforeUpload={(e) => {
                              onCloseModalUpload();
                              uploadImage(afterPhotoName, e);
                            }}
                            name={afterPhotoName}
                            url={afterPhoto}
                            isOpen={Boolean(openModalUpload)}
                            setValue={setValue}
                            onClose={onCloseModalUpload}
                          />
                          <ModalDelete
                            isOpen={Boolean(openModalDeleteImg)}
                            isSubmitting={false}
                            onDeleteRecord={async () => {
                              setValue(afterPhotoName, null);
                              setValue(afterPhotoKey, null);
                              deleteImage(afterPhotoName, watch(afterPhotoKey));
                              onCloseModalDeleteUpload();
                            }}
                            onClose={onCloseModalDeleteUpload}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="input-field">
                  <InputLabel>
                    作業コメント <span>(報告書に記載)</span>
                  </InputLabel>
                  <TextField
                    multiline
                    rows={2}
                    maxRows={10}
                    {...register(`work_report_attributes.${index}.comment`)}
                    name={`work_report_attributes.${index}.comment`}
                    placeholder="水道のパッキンが割れてました。"
                    disabled={isView}
                  />
                  {errorComment && <p className="error">{errorComment}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

const WorkReport: FC<ReceivedProps> = (props) => (
  <WorkReportLayout {...useWorkReport(props)} />
);

export default WorkReport;
